// Generated by Framer (d0dedfe)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle1 from "../css/DoBE6BLpS";
import * as sharedStyle from "../css/gzljWXbl0";

const serializationHash = "framer-tMQEA"

const variantClassNames = {Lk5Ps0AVj: "framer-v-nngu52"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, text, title, width, ...props}) => { return {...props, MpNcKiybe: text ?? props.MpNcKiybe ?? "O Interpretador de Arquivos pode ser acessado em qualquer chat", QPcAwPvaC: title ?? props.QPcAwPvaC ?? "Abra ou Crie um Novo Chat"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, QPcAwPvaC, MpNcKiybe, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Lk5Ps0AVj", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-nngu52", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Lk5Ps0AVj"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-1a130d75-a51f-4a3b-916c-07fcd01f9c0e, rgb(0, 89, 79))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 89, 79, 0.14)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-gqz6wm"} data-styles-preset={"gzljWXbl0"}>Abra ou Crie um Novo Chat</motion.h3></React.Fragment>} className={"framer-11vne6g"} data-framer-name={"Comece a utilizar agora mesmo e acesse:"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"EpZXBMPMR"} style={{"--framer-paragraph-spacing": "0px"}} text={QPcAwPvaC} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-2qcbht"} data-styles-preset={"DoBE6BLpS"}>O Interpretador de Arquivos pode ser acessado em qualquer chat</motion.p></React.Fragment>} className={"framer-1mf4l9m"} data-framer-name={"Comece a utilizar agora mesmo e acesse:"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"dk6Fj0COJ"} style={{"--framer-paragraph-spacing": "0px"}} text={MpNcKiybe} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tMQEA.framer-70ow09, .framer-tMQEA .framer-70ow09 { display: block; }", ".framer-tMQEA.framer-nngu52 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: hidden; padding: 20px; position: relative; width: 1080px; will-change: var(--framer-will-change-override, transform); }", ".framer-tMQEA .framer-11vne6g, .framer-tMQEA .framer-1mf4l9m { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tMQEA.framer-nngu52 { gap: 0px; } .framer-tMQEA.framer-nngu52 > * { margin: 0px; margin-bottom: calc(6px / 2); margin-top: calc(6px / 2); } .framer-tMQEA.framer-nngu52 > :first-child { margin-top: 0px; } .framer-tMQEA.framer-nngu52 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css, ".framer-tMQEA[data-border=\"true\"]::after, .framer-tMQEA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 118
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"QPcAwPvaC":"title","MpNcKiybe":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerlutVyKWRz: React.ComponentType<Props> = withCSS(Component, css, "framer-tMQEA") as typeof Component;
export default FramerlutVyKWRz;

FramerlutVyKWRz.displayName = "SEO - Texto com Titulo";

FramerlutVyKWRz.defaultProps = {height: 118, width: 1080};

addPropertyControls(FramerlutVyKWRz, {QPcAwPvaC: {defaultValue: "Abra ou Crie um Novo Chat", displayTextArea: false, title: "Title", type: ControlType.String}, MpNcKiybe: {defaultValue: "O Interpretador de Arquivos pode ser acessado em qualquer chat", description: "", displayTextArea: true, placeholder: "", title: "Text ", type: ControlType.String}} as any)

addFonts(FramerlutVyKWRz, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})